jQuery( document ).ready(function( $ ){
  $( "#repository-html-snippet" ).next().insertBefore( "#repository-html-snippet" );
  $( "#repository-html-snippet" ).css( "margin-top", "10px" );
  $( ".read-more" ).each( function( index ) {
    $( this ).hide();
    $( this ).next( ".details" ).show();
    $( this ).closest( ".summary" ).hide();
    $( this ).closest( ".summary" ).next( ".details" ).show();
  });
  $( ".term.index" ).find( ".actions > ul" ).append( "<li><a class=\"c-btn\" href=\""+window.location.href+"/term/browseTerm\" title=\"Browse\">Browse</a></li>" )
});
